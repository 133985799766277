import React, { useState } from 'react';

const SmartFeedNavigation = ({ active, onNavChange }) => {
  return (
    <div>
      <div className="vf-navigation btn-group flex-wrap flex-md-nowrap w-100">
        <button
          className={`btn border ${
            active === 'Careers' ? 'btn--primary' : 'btn-light'
          } w-100`}
          onClick={() => {
            onNavChange('Careers');
          }}
        >
          Current vacancies
        </button>
        <button
          className={`btn border ${
            active === 'Alerts' ? 'btn--primary' : 'btn-light'
          } w-100`}
          onClick={() => {
            onNavChange('Alerts');
          }}
        >
          Register for job alerts
        </button>
      </div>
    </div>
  );
};

export default SmartFeedNavigation;

import React, { useState } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import SmartFeedList from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList';
import SmartFeedFilters from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters';
import SmartFeedNavigation from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedNavigation';
import SmartFeedJobAlerts from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/smartfeed/FiltersContext';

export default function HomepagePage_Content(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: { config },
  } = props;

  const siteConfig = config;

  const companyName =
    siteConfig?.CompanyName || props?.smartfeedCompanyData?.CompanyName || '';

  const [vacancyLoading, setVacancyLoading] = useState(true);

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
      if (pair[0] === 'IsIntranet') {
        internalView = pair[1];
      }
    }
  }

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={props.trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
      useGroupVacsLink={props.useGroupVacsLink}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <>
          <div
            className="banner"
            style={{
              backgroundRepeat: 'no-repeat',
              background: `url(/banner.png)`,
            }}
          >
            <div className="container">
              <h1 className="text-left text-white">
                Careers
              </h1>
            </div>
          </div>
          <div className="home-white-fullwidth">
            <div className="container py-3">
              <div className="row">
                <div className="col-12 mb-3">
                  <SmartFeedNavigation
                    active={filters?.postingtype || 'Careers'}
                    onNavChange={onNavChange}
                  />
                </div>
              </div>
              {filters?.postingtype !== 'Alerts' ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      {siteConfig.showAdvancedFilters && (
                        <SmartFeedFilters
                          siteConfig={siteConfig}
                          apiKey={siteConfig.apiKey || props.apiKey}
                          group={
                            siteConfig.group
                              ? siteConfig.group
                              : props.groupOrIdParam === 'id'
                              ? false
                              : props.groupOrIdParam === 'group'
                              ? true
                              : false
                          }
                          groupOrIdParam={props.groupOrIdParam}
                        />
                      )}
                    </div>

                    <div className="col-12">
                      <SmartFeedList
                        siteConfig={siteConfig}
                        apiKey={siteConfig.apiKey || props.apiKey}
                        group={
                          siteConfig.group
                            ? siteConfig.group
                            : props.groupOrIdParam === 'id'
                            ? false
                            : props.groupOrIdParam === 'group'
                            ? true
                            : false
                        }
                        smartfeedCompanyData={
                          siteConfig.smartfeedCompanyData ||
                          props.smartfeedCompanyData
                        }
                        trackEvent={props.trackEvent}
                        useGroupVacsLink={props.useGroupVacsLink}
                        vacancyLoading={vacancyLoading}
                        setVacancyLoading={setVacancyLoading}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-8">
                    <SmartFeedJobAlerts
                      siteConfig={siteConfig}
                      trackEvent={props.trackEvent}
                      apiKey={props.apiKey}
                      gdprKey={props.gdprKey}
                      group={props.group}
                      CompanyGroupName={props.CompanyGroupName}
                      VacancyPageBaseURL={props.VacancyPageBaseURL}
                      ReturnToSearchURL={props.ReturnToSearchURL}
                      OptInLink={props.OptInLink}
                      UnsubscribeLink={props.UnsubscribeLink}
                      VacancyPageBaseURL={props.VacancyPageBaseURL}
                      ReturnToSearchURL={props.ReturnToSearchURL}
                      location={siteConfig.showLocationJA}
                      region={siteConfig.showRegionJA}
                      category={siteConfig.showCategoryJA}
                      industry={siteConfig.showIndustryJA}
                      jobType={siteConfig.showJobTypeJA}
                      jobTime={siteConfig.showJobTimeJA}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
